.app {
  background-color: #ffffff;
  padding: 32px;
  margin: 32px;
  border-radius: 3px;
  border: 1px solid #e1e4e8;
  flex-grow: 1;
  max-width: 800px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.tagline {
  font-size: 12px;
}

.form-field {
  margin: 40px 0;
}

.form-description {
  margin-bottom: 6px;
}

.form-error {
  margin-top: 12px;
  color: red;
}

.error-text {
  color: red;
}

.bold {
  font-weight: 700;
}
